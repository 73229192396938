/* eslint-disable no-console */
import React from 'react';
import ReactDOM from 'react-dom';
import Footer from './footer/Footer';
import App from './App';

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js').then(registration => {
      console.log('SW registered: ', registration);
    }).catch(registrationError => {
      console.log('SW registration failed: ', registrationError);
    });
  });
}

ReactDOM.render(
  <App />,
  document.getElementById('root'),
);

ReactDOM.render(
  <Footer />,
  document.getElementById('footer'),
);