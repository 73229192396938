import React from 'react';
import { Row, Col } from 'antd';
import { utilsCss } from '@consys/ui';
// import logo from '../../img/logo_simples.webp';
import pkg from '../../package.json';

const Footer: React.FC = () => {
  return (
    <Row className={utilsCss.center}>
      <Col sm={{ span: 14, offset: 5 }}
        xs={{ span: 20, offset: 2 }}
        className={[utilsCss.pt1, utilsCss.mt1, utilsCss.borderTop].join(' ')}>
        {/* <img src={logo}
          alt='Logo Seja MEI'
          width={120}
          height={48} /> */}
        <Row className={[utilsCss.mt1, utilsCss.h6].join(' ')}>
          <Col span={24}>
            <b>
              <a href='http://consystec.com.br/'
                target='_blank'
                rel="noopener noreferrer"
                style={{ color: 'inherit' }}>
                Consystec Sistemas
              </a>
              &nbsp;{new Date().getFullYear()}</b>
          </Col>
          <Col span={24}>
            versão {pkg.version}
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Footer;