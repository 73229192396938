import React, { lazy } from 'react';
import { Suspense } from '@consys/ui';

const Root = lazy(() => import('./router/Root'));

const App: React.FC = () => {
  return (
    <Suspense>
      <Root />
    </Suspense>
  );
}

export default App;